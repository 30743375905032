const USER_DETAILS = "USER_DETAILS";
const LOGIN_FAIL = "LOGIN_FAIL";
const USER_SIGNUP = "USER_SIGNUP"
const SIGNUP_FAIL = "SIGNUP_FAIL"
// const USER_LOGOUT="USER_LOGOUT"


export {
    USER_DETAILS, 
    LOGIN_FAIL,
    USER_SIGNUP,
    SIGNUP_FAIL,
    // USER_LOGOUT,
};
