import React from 'react'
import Mainpage from '../components/Mainpage'
import NavBar from '../components/Navbar'
function Index() {
  return (
    <>
    <NavBar/>
    <Mainpage/>
    </>
  )
}

export default Index
